<template>
	<div>
		<PasswordReset />
	</div>
</template>

<script>
import PasswordReset from '@/components/templates/PasswordReset';

export default {
	components: {
		PasswordReset
	}	
}
</script>